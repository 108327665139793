import { axios } from '@/utils/request'

const api = {
    pagesRefill: 'charging/refill/pagesRefill', // 查询充值订单
}
export default api

// 查询设备
export const pagesRefillApi = (params) => {
    return axios({
        url: api.pagesRefill,
        method: 'post',
        params
    })
}