<!-- 微信用户列表 -->
<template>
    <div class="warp">
      <el-card class="warp-content">
        <!-- 搜素区域 -->
        <div class="page-select">
          <el-row style="width: 100%; margin: 0 0 15px 0">
            <el-col :span="24" style="text-align: right">
              <el-select
                class="search-input"
                placeholder="请选择充值类型"
                size="small"
                v-model="topUpType"
                @change="handleStateChange"
              >
                <el-option
                  v-for="item in topUpTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  {{ item.label }}
                </el-option>
              </el-select>
              <el-input
                v-if="topUpType == 1"
                placeholder="请输入手机号"
                class="search-input"
                prefix-icon="search"
                size="small"
                clearable
                @input="searchInput"
                v-model="createUserValue"
              >
              </el-input>
              <el-input
                v-else
                placeholder="请输入卡号"
                class="search-input"
                prefix-icon="search"
                size="small"
                clearable
                @input="searchInput"
                v-model="cardMark"
              >
              </el-input>
              <el-button type="primary" size="small" @click="searchClick"
                >搜索</el-button
              >
              <!-- <el-button type="primary" size="small" @click="downloadTemplate"
                >导出Excel</el-button
              > -->
            </el-col>
          </el-row>
        </div>

        <!-- 钱包充值表格区域 -->
        <div class="page-container" v-if="topUpType == 1">
          <el-table
            ref="tableRef"
            :data="tableData"
            style="width: 100%"
            max-height="630px"
            :header-cell-style="{ background: '#F2F4F8' }"
            @selection-change="handleSelectionChange"
          >
  <!--          <el-table-column type="selection" align="center" width="80" />-->
            <el-table-column
              v-for="(item, index) in tableColumnData"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              align="center"
              show-overflow-tooltip
            >
              <template #default="{ row }" v-if="item.prop === 'headUrl'">
                <img
                  class="imgUrl"
                  :src="row.headUrl"
                  @click="onPicture(row.headUrl)"
                />
              </template>
              <template #default="{ row }" v-if="item.prop === 'status'">
                <span :class="sizeClass(row)">{{ statusSize(row) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 卡充值表格区域 -->
        <div class="page-container" v-else>
          <el-table
            ref="tableRef"
            :data="tableData"
            style="width: 100%"
            max-height="630px"
            :header-cell-style="{ background: '#F2F4F8' }"
            @selection-change="handleSelectionChange"
          >
  <!--          <el-table-column type="selection" align="center" width="80" />-->
            <el-table-column
              v-for="(item, index) in tableColumnData2"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              align="center"
              show-overflow-tooltip
            >
              <template #default="{ row }" v-if="item.prop === 'status'">
                <span :class="sizeClass(row)">{{ statusSize(row) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页区域 -->
        <div class="page-foot">
          <page-nation
            :total="total"
            @pageChange="handleSizeChange"
            @currentChange="handleCurrentChange"
          ></page-nation>
        </div>
      </el-card>
    </div>
  </template>

  <script>
  import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
  import pageNation from "@/components/base/pageNation.vue";
  import {
    pagesRefillApi,
  } from "@/api/indentManagement/topupList/topupList.js";
  import { requestApi } from "@/utils/requestApi";
  import { debounces } from "@/common/utils";
  export default {
    name: "AccountSearch",
    components: { pageNation },
    setup() {
      const { proxy } = getCurrentInstance();
      const data = reactive({
        createUserValue: '',   //  用户昵称搜索框
        cardMark: '',     // 卡号搜索框
        topUpType: 1, // 搜索状态
        tableData: [
          // {deviceMac:1}
        ], // 表格数据
        imgVisible: false, // 图片预览弹框
        imgUrl: null, //
        total: 0,
        pageSize: 10, // 一页显示十条
        currentPage: 1, //默认第一页
        deviceMac: "",
        phone: "",
        status: "",
        tradeNo: "",
        userId: "",
        userInfo: null,
        selection: [],
        disabled: true,
        formData: {
          alarTime: [], //起始时间
        },
        tableColumnData: [
          {
            prop: "refillId",
            label: "付款编号",
          },
          {
            prop: "stationName",
            label: "站点名称"
          },
          {
            prop: "phone",
            label: "用户手机号",
          },
          {
            prop: "refillMoney",
            label: "付款金额(元)",
          },
          {
            prop: "presentMoney",
            label: "赠送金额(元)",
          },
          {
            prop: "createTime",
            label: "创建时间",
          },
        ],
        tableColumnData2: [
          {
            prop: "cardMark",
            label: "卡号",
          },
          {
            prop: "phone",
            label: "绑定用户",
          },
          {
            prop: "refillMoney",
            label: "付款金额(元)",
          },
          {
            prop: "createTime",
            label: "创建时间",
          },
        ],
        topUpTypeList: [
          {
            value: 1,
            label: '钱包充值'
          },
          {
            value: 2,
            label: '卡充值'
          }
        ],
        // 订单详情对话框
        dialogVisible: false,
        dialogTitleName: "订单详情",
        // 订单详情form
        detailsFormData: {
          headUrl: null, // 微信头像
          userId: null, //用户id
          phone: null, // 手机号
          tradeNo: null, // 订单号
          deviceMac: null, // 设备号
          electricity: null, // 总用电量
          initialPower: null, // 起始功率
          orderPrice: null, // 订单总价
          practical: null, // 实际支付金额
          portNumber: null, // 端口号
          chargeType: null, // 收费类型
          orgId: null, // 充电站地址（主键）
          stationName: null, // 充电站名称
        },
        editDeviceRules: {},
      });
      onMounted(() => {
        //   proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        methods.selectPages();
      });
      // 节流搜索按钮
      const searchInput = debounces(() => {
        methods.selectPages();
      }, 500);
      const methods = {
        downloadTemplate() {},
        //下拉框查询
        handleStateChange() {
          proxy.currentPage = 1
          methods.selectPages();
        },
        // 搜索按钮
        searchClick() {
          methods.selectPages();
        },
        // 查询充值订单分页
        async selectPages() {
          await requestApi(
            () => {
              let params = {
                current: proxy.currentPage,
                size: proxy.pageSize,
                userId: "",
                type: proxy.topUpType,
              }
              if(proxy.topUpType == 1) {
                params.phone = proxy.createUserValue
              } else {
                params.cardMark = proxy.cardMark
              }
              return pagesRefillApi(params);
            },
            (res) => {
              if (res.code === 200) {
                console.log(res);
                proxy.tableData = res.data.records.map((it) => {
                  return {
                    refillId: it.refillId, // 付款编号
                    cardMark: it.cardMark,
                    createUser: it.createUser,  // 用户昵称
                    refillMoney: (it.refillMoney / 100).toFixed(2),  // 付款金额
                    presentMoney: (it.presentMoney / 100).toFixed(2),  // 赠送金额
                    createTime: it.createTime,  // 创建时间
                    phone: it.phone,     //
                    stationName: it.stationName     // 站点名称
                  };
                });
                proxy.total = res.data.records ? parseInt(res.data.total) : 0;
              }
            }
          );
        },
        // 表格分页 改变每页数量时触发
        handleSizeChange(val) {
          proxy.pageSize = val;
          methods.selectPages();
        },
        // 表格分页 改变页数时触发
        handleCurrentChange(val) {
          proxy.currentPage = val;
          methods.selectPages();
        },
        // 删除订单
        async confirmEvent(id) {
          const params = {
            Ids: id.indentId,
          };
          await requestApi(
            () => {
              return delMerchantApi(params);
            },
            (res) => {
              if (res.code === 200) {
                methods.selectPages();
              }
            }
          );
        },
        // 点击图片放大
        onPicture(row) {
          console.log(row.headUrl);
          proxy.imgUrl = row;
          data.imgVisible = true;
        },
        charTYpe(val) {
          console.log(val,111);
          if (val == 1) {
            return "电量收费";
          } else {
            return "计时收费";
          }
        },
        // 状态显示
        statusSize(fol) {
          if (fol.status == 1) {
            return "待支付...";
          } else if (fol.status == 2) {
            return "进行中...";
          } else if (fol.status == 3) {
            return "已取消";
          } else if (fol.status == 4) {
            return "已结束";
          } else {
            return "故障";
          }
        },
        // 状态显示颜色
        sizeClass(fol1) {
          if (fol1.status == 1) {
            return "fontcolor";
          } else if (fol1.status == 2) {
            return "fontcolor1";
          } else if (fol1.status == 3) {
            return "fontcolor2";
          } else if (fol1.status == 4) {
            return "fontcolor2";
          } else {
            return "fontcolor3";
          }
        },
      };
      return {
        ...toRefs(data),
        searchInput,
        ...methods,
      };
    },
  };
  </script>
  <style lang="less" scoped>
  .warp {
    height: 100%;
    .warp-content {
      height: 100%;
    }
  }
  .page-select {
    width: 100%;
    margin: 0 auto;
  }
  .page-container {
    width: 100%;
    //margin-top: -28px;
  }
  .page-foot {
    width: 100%;
    margin-top: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .my-btn {
      height: 28px;
    }
  }
  .search-input {
    width: 202px;
    margin-right: 4px;
    margin-top: 6px;
  }
  .fontcolor {
    color: #00cd00;
  }
  .fontcolor1 {
    color: #00ee00;
  }
  .fontcolor2 {
    color: #ee4000;
  }
  .fontcolor3 {
    color: #ee0000;
  }
  .search-input-class {
    width: 100%;
  }
  /deep/.el-form-item__label {
    white-space: nowrap;
  }
  /deep/.el-form-item {
    width: 100%;
  }
  .imgUrl {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .headImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  </style>
